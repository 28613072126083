
import { defineComponent, onMounted, ref } from 'vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import { useRoute, useRouter } from 'vue-router';
import { CategorylistDataList, SaveBody, FormDataNormalVal, FeedGoodsType } from '../types/feed';
import { getCategorylist, getFormData, getGoodsTags, getRecommend, save } from '../api/feed';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormSkeleton from '@/components/formSkeleton/FormSkeleton.vue';
import { getRoute } from '@/tools/config';
import {
  ElMessage,
  ElInput,
  ElFormItem,
  ElForm,
  ElButton,
  ElSelect,
  ElAutocomplete,
  ElRadioGroup,
  ElRadio,
  ElOption,
} from 'element-plus';

type FeedIdType = string | undefined;

export interface FormDataNormal {
  id?: string;
  name: string;
  type: FeedGoodsType; //类型 all=全部商品 category=主分类 tag=标签 recommend=推荐
  normalVal: {
    category: FormDataNormalVal[];
    tag: FormDataNormalVal[];
    recommend: FormDataNormalVal[];
  };
}

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const feedId: FeedIdType = route.params.feedId as FeedIdType;

    const formData = ref<FormDataNormal>({
      id: feedId,
      name: '',
      type: 'all',
      normalVal: {
        category: [{ id: '' }],
        tag: [{ id: '' }],
        recommend: [{ id: '' }],
      },
    });
    const categories = ref<CategorylistDataList[]>([]);

    const categoriesLoading = ref(false);
    // 获取分类列表
    const initCategories = async () => {
      const { status, data } = await getCategorylist(categoriesLoading);

      if (!status) return;

      categories.value = data?.list || [];
    };

    const formDataLoading = ref(false);
    // 获取表单数据
    const initFormData = async () => {
      if (!feedId) return;
      const { status, data } = await getFormData({ id: feedId }, formDataLoading);

      if (!status) return;

      // 将返回数据进行处理: data ➡️ formData
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const item = data[key];
          if (key === 'value' && data.type !== 'all') {
            formData.value.normalVal[data.type] = JSON.parse(item);
          } else {
            formData.value[key] = item;
          }
        }
      }
    };

    const searchTag = (keyword, cb) => {
      search(keyword, cb, 'tag');
    };

    const serachRecommend = (keyword, cb) => {
      search(keyword, cb, 'recommend');
    };

    const search = (keyword, cb, type: FeedGoodsType) => {
      setTimeout(async () => {
        const { status, data } =
          type === 'tag' ? await getGoodsTags({ keyword }) : await getRecommend({ keyword });

        if (!status) return;

        if (data?.list) {
          const { list } = data;

          const searchList = list.map(item => {
            return {
              value: item.base_name,
              id: item.id,
            };
          });
          cb(searchList);
        } else {
          cb([]);
        }
      }, 500);
    };

    // 选择搜索结果
    const select = (item, type: FeedGoodsType, index: number) => {
      formData.value.normalVal[type].splice(index, 1, {
        id: item.id,
        base_name: item.value,
      });
    };

    // 添加分类
    const addCategory = (type: FeedGoodsType) => {
      formData.value.normalVal[type].push({
        id: '',
      });
    };
    // 删除分类
    const delCategory = (index: number, type: FeedGoodsType) => {
      formData.value.normalVal[type].splice(index, 1);
    };

    const saveLoading = ref(false);
    const form = ref(); // 获取表单
    const backRouter = getRoute('feed');
    const triggerSave = async () => {
      // 提交时验证
      let allowRunning = false;
      form.value.validate(valid => {
        if (valid) {
          allowRunning = true;
        }
      });

      const data: SaveBody = {} as SaveBody;
      const { name, type, normalVal, id } = formData.value;

      // 分类，商品标签，商品推荐 不能选择相同的选项
      let noRunningWithSameValue = false;
      const curVal: { base_name?: string; id: string }[] = normalVal[type];
      if (type !== 'all') {
        curVal.forEach((item, index) => {
          if (noRunningWithSameValue) return;

          const errIndex = curVal.findIndex((filterItem, filterIndex) => {
            return filterItem.id === item.id && index !== filterIndex;
          });

          if (errIndex >= 0) {
            allowRunning = false;
            noRunningWithSameValue = true;

            let messageTarget = '主分类';
            if (type === 'tag') {
              messageTarget = '商品标签';
            } else if (type === 'recommend') {
              messageTarget = '商品推荐';
            }

            ElMessage({
              type: 'warning',
              message: `${messageTarget}不能相同`,
            });
          }
        });
      }

      if (!allowRunning) return;

      data.id = id;
      data.name = name;
      data.type = type;
      data.value = JSON.stringify(curVal);

      const { status } = await save(data, saveLoading);

      if (status) {
        ElMessage({
          type: 'success',
          message: '保存成功',
        });
        router.push(backRouter);
      }
    };

    const changeSearchKey = (target: FormDataNormalVal) => {
      target.id = '';
    };

    onMounted(() => {
      initCategories();
      initFormData();
    });
    return {
      route,
      feedId,
      formData,
      categories,
      addCategory,
      delCategory,
      triggerSave,
      searchTag,
      select,
      serachRecommend,
      saveLoading,
      categoriesLoading,
      formDataLoading,
      form,
      changeSearchKey,
    };
  },
  components: {
    PageHeader,
    FormFooter,
    FormSkeleton,
    ElInput,
    ElForm,
    ElFormItem,
    ElButton,
    ElSelect,
    ElAutocomplete,
    ElRadio,
    ElRadioGroup,
    ElOption,
  },
});
